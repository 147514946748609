import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="wrapper">
    <h2>404: Not Found</h2>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        </section>
  </Layout>
)

export default NotFoundPage
